// File: src/components/Header.js
import React from 'react';
import styled from 'styled-components';

const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: rgba(10, 10, 10, 0.8);
  backdrop-filter: blur(5px);
  padding: 1rem;
`;

const NavContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const NavList = styled.ul`
  list-style-type: none;
  display: flex;
  gap: 1rem;

  @media (max-width: 768px) {
    gap: 0.5rem;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const NavItem = styled.li`
  a {
    color: var(--text-color);
    text-decoration: none;
    font-family: 'Syncopate', sans-serif;
    font-size: 0.8rem;
    letter-spacing: 0.1em;
    transition: color 0.3s ease;

    &:hover {
      color: var(--accent-color);
    }
  }

  @media (max-width: 768px) {
    a {
      font-size: 0.7rem;
      padding: 0.3rem 0.5rem;
    }
  }
`;

const Header = () => {
  const scrollTo = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Nav>
      <NavContent>
        <NavList>
          <NavItem><a href="#home" onClick={(e) => {
            e.preventDefault();
            scrollTo('home');
          }}>HOME</a></NavItem>
          <NavItem><a href="#about" onClick={(e) => {
            e.preventDefault();
            scrollTo('about');
          }}>ABOUT</a></NavItem>
          <NavItem><a href="#albums" onClick={(e) => {
            e.preventDefault();
            scrollTo('albums');
          }}>ALBUMS</a></NavItem>
          <NavItem><a href="#contact" onClick={(e) => {
            e.preventDefault();
            scrollTo('contact');
          }}>CONTACT</a></NavItem>
        </NavList>
      </NavContent>
    </Nav>
  );
};

export default Header;