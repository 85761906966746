import React from 'react';
import styled from 'styled-components';

const PolicyOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const PolicyContainer = styled.div`
    background-color: #1a1a1a;
    color: #fff;
    padding: 2rem;
    border-radius: 10px;
    max-width: 800px;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
`;

const CloseButton = styled.button`
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    color: #fff;
    font-size: 1.5rem;
    cursor: pointer;
`;

const PrivacyPolicy = ({onClose}) => (
    <PolicyOverlay>
        <PolicyContainer>
            <CloseButton onClick={onClose}>&times;</CloseButton>
            <h1>Privacy Policy</h1>
            <p>Last updated: {new Date().toLocaleDateString()}</p>
            <h2>1. Introduction</h2>
            <p>Elegant Echoes ("we", "our", or "us") respects your privacy and is committed to protecting your personal
                data.</p>
            <h2>2. The data we collect</h2>
            <p>We collect and process the following data:</p>
            <ul>
                <li>Usage Data: Information on how you use our website.</li>
                <li>Cookie Data: Data stored in cookies on your device.</li>
            </ul>
            <h2>3. How we use your data</h2>
            <p>We use your data to:</p>
            <ul>
                <li>Analyze website usage to improve user experience.</li>
                <li>Personalize content and advertisements.</li>
            </ul>
            <h2>4. Google Analytics</h2>
            <p>We use Google Analytics to analyze the use of our website. Google Analytics gathers information about
                website use by means of cookies. The information gathered relating to our website is used to create
                reports about the use of our website.</p>
            <h2>5. Your rights</h2>
            <p>You have the right to:</p>
            <ul>
                <li>Access your personal data</li>
                <li>Rectify your personal data</li>
                <li>Erase your personal data</li>
                <li>Restrict processing of your personal data</li>
                <li>Object to processing of your personal data</li>
                <li>Data portability</li>
            </ul>
            <h2>6. Changes to this policy</h2>
            <p>We may update this privacy policy from time to time. We will notify you of any changes by posting the new
                privacy policy on this page.</p>
            <h2>7. Contact us</h2>
            <p>For any questions about this privacy policy, please contact us at: aiechoesmusic@proton.me</p>
        </PolicyContainer>
    </PolicyOverlay>
);

export default PrivacyPolicy;