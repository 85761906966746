// src/components/Home.js
import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

const HeroSection = styled.section`
  grid-column: 1 / -1;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
`;

const Title = styled.h1`
  font-family: 'Syncopate', sans-serif;
  font-size: clamp(3rem, 8vw, 6rem);
  line-height: 1.2;
  margin-bottom: 1rem;
  animation: glitch 5s infinite;
`;

const Subtitle = styled.p`
  font-size: clamp(1rem, 2vw, 1.2rem);
  max-width: 600px;
  margin-bottom: 2rem;
`;

const CtaButton = styled.a`
  background-color: var(--accent-color);
  color: var(--primary-color);
  padding: 1rem 2rem;
  text-decoration: none;
  font-family: 'Syncopate', sans-serif;
  font-weight: 700;
  font-size: clamp(0.8rem, 1.5vw, 1rem);
  letter-spacing: 0.1em;
  transition: all 0.3s ease;

  &:hover {
    background-color: var(--text-color);
    color: var(--primary-color);
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(255, 51, 102, 0.4);
  }
`;

const AnimatedTitle = animated(Title);
const AnimatedSubtitle = animated(Subtitle);
const AnimatedCtaButton = animated(CtaButton);

const Home = () => {
  const titleProps = useSpring({
    from: { opacity: 0, transform: 'translateY(-50px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    delay: 300,
  });

  const subtitleProps = useSpring({
    from: { opacity: 0, transform: 'translateY(50px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    delay: 600,
  });

  const buttonProps = useSpring({
    from: { opacity: 0, transform: 'scale(0.8)' },
    to: { opacity: 1, transform: 'scale(1)' },
    delay: 900,
  });

  return (
    <HeroSection id="home">
      <AnimatedTitle style={titleProps}>ELEGANT ECHOES</AnimatedTitle>
      <AnimatedSubtitle style={subtitleProps}>
        Immerse yourself in avant-garde soundscapes that challenge the boundaries of auditory perception.
      </AnimatedSubtitle>
      <AnimatedCtaButton
        style={buttonProps}
        href="#albums"
        onClick={(e) => {
          e.preventDefault();
          document.getElementById('albums').scrollIntoView({ behavior: 'smooth' });
        }}
      >
        EXPLORE SONIC REALMS
      </AnimatedCtaButton>
    </HeroSection>
  );
};

export default Home;