import React from 'react';
import styled from 'styled-components';

const ContactSection = styled.section`
  grid-column: 1 / -1;
  padding: 6rem 2rem;
  text-align: center;
`;

const ContactLink = styled.a`
    display: inline-block;
    margin: 1rem;
    padding: 1rem;
  background-color: var(--accent-color);
  color: var(--primary-color);
    text-decoration: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: var(--text-color);
  }
`;

const Contact = () => {
  return (
    <ContactSection id="contact">
      <h2>Contact Us</h2>
        <ContactLink href="https://instagram.com/elegantechoesmusic" target="_blank">
            Instagram
        </ContactLink>
    </ContactSection>
  );
};

export default Contact;
