import React from 'react';
import styled from 'styled-components';

const FooterWrapper = styled.footer`
  grid-column: 1 / -1;
  text-align: center;
  font-size: 0.8rem;
  opacity: 0.7;
  padding: 2rem 0;
`;

const Footer = () => (
  <FooterWrapper>
    <p>&copy; 2024 Elegant Echoes. All rights ethereal.</p>
  </FooterWrapper>
);

export default Footer;