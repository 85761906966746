import vintageJazzLoungeCover from '../images/vintage-jazz-lounge.jpg';
import echoOfStudyCover from '../images/echoes-of-study.jpg';
import ambientWorkspacesCover from '../images/ambient-workspaces.jpg';
import peacefulPianoMoodsCover from '../images/peaceful-piano.jpg';
import chillElectronicVibesCover from '../images/chill-electronic.jpg';
import naturesSerenityCover from '../images/natures-serenity.jpg';
import jazzCafeAmbienceCover from '../images/jazz-cafe.jpg';
import upliftingAcousticCover from '../images/uplifting-acoustic.jpg';
import meditativeSoundscapesCover from '../images/meditative-soundscapes.jpg';
import retroLoungeGroovesCover from '../images/retro-lounge.jpg';


export const albumsData = [
    {
        id: "VJL001",
        title: "Vintage Jazz Lounge",
        genre: "Jazz, Smooth, Ambient",
        description: "Step into a timeless era of sophistication with our vintage jazz collection, perfect for creating an elegant atmosphere in any setting.",
        tags: ["vintage-jazz", "smooth-jazz", "ambient-music", "instrumental", "lounge-music", "relaxation"],
        idealSongLength: "3-5 minutes",
        releaseDate: "2024-06-24",
        cover: vintageJazzLoungeCover,
        streamingLinks: {
            spotify: "https://open.spotify.com/album/0xbqoX2Gynm5kTcvSUOU8z",
            appleMusic: "https://music.apple.com/us/album/vintage-jazz-lounge/1751748632",
            amazonMusic: "https://music.amazon.it/albums/B0D6X232ZP",
            youtube: "https://www.youtube.com/playlist?list=OLAK5uy_n2oWfOpWKabJuMUzXp_cThjMA4Nsz-ew8",
        },
        seoKeywords: "vintage jazz, smooth jazz, ambient music, instrumental jazz, lounge music, relaxation playlist"
    },
    {
        id: "LSB002",
        title: "Echoes of Study",
        genre: "Lo-Fi Hip-Hop",
        description: "Enhance your focus and productivity with our curated collection of lo-fi beats, designed to create the perfect study or work atmosphere.",
        tags: ["lo-fi", "study-music", "focus-enhancing", "productivity", "chill-beats", "background-music"],
        idealSongLength: "2-4 minutes",
        releaseDate: "2024-06-24",
        streamingLinks: {
            spotify: "https://open.spotify.com/album/4K5hA8ktBBOqkFaAkiIz13",
            appleMusic: "https://music.apple.com/us/artist/elegant-echoes/1335856201",
            amazonMusic: "https://music.amazon.it/albums/B0D7CPHTBK",
            youtube: "https://www.youtube.com/playlist?list=OLAK5uy_mS5oQ_dTUpLOfYsoSjJjvPKgiZMnshLTE",
        },
        cover: echoOfStudyCover,
        seoKeywords: "lo-fi music, study playlist, focus music, productivity enhancement, chill beats, background music"
    },
    {
        id: "AW003",
        title: "Ambient Workspaces: Focused Atmospheres",
        genre: "Ambient",
        description: "Transform any environment into a serene workspace with our ambient soundscapes, crafted to boost concentration and creativity.",
        tags: ["ambient-music", "workspace-enhancement", "focus-music", "productivity", "background-sounds", "concentration"],
        idealSongLength: "4-6 minutes",
        releaseDate: "2024-06-24",
        cover: ambientWorkspacesCover,
        streamingLinks: {
            spotify: "https://open.spotify.com/album/6bmKkl0RLwRyUUFhCxn3ek",
            appleMusic: "https://music.apple.com/us/artist/elegant-echoes/1335856201",
            amazonMusic: "https://music.amazon.it/albums/B0D6X2R9JB",
            youtube: "https://www.youtube.com/playlist?list=OLAK5uy_k_SUdsF4opsM7hs6Vf4w7oGfdu6PSFDrs",
        },
        seoKeywords: "ambient music, workspace enhancement, focus music, productivity sounds, background audio, concentration improvement"
    },
    {
        id: "PPM004",
        title: "Peaceful Piano Moods: Classical Serenity",
        genre: "Classical Piano",
        description: "Immerse yourself in the soothing melodies of solo piano, perfect for relaxation, meditation, or creating a calm atmosphere.",
        tags: ["piano-music", "classical-piano", "relaxation", "meditation-music", "calm-melodies", "instrumental"],
        idealSongLength: "3-5 minutes",
        releaseDate: "2024-06-24",
        cover: peacefulPianoMoodsCover,
        streamingLinks: {
            spotify: "https://open.spotify.com/album/5Th2JgYq9tP7wZjiWFLUAb",
            appleMusic: "https://music.apple.com/us/artist/elegant-echoes/1335856201",
            amazonMusic: "https://music.amazon.it/albums/B0D6WMSB65",
            youtube: "https://www.youtube.com/playlist?list=OLAK5uy_nrWpAr57-iswtiwAVs6BibZyUlQebUX0o",
        },
        seoKeywords: "piano music, classical piano, relaxation sounds, meditation music, calm melodies, instrumental piano"
    },
    {
        id: "CEV005",
        title: "Chill Electronic Vibes: Relax and Unwind",
        genre: "Electronic Chill",
        description: "Experience a fusion of electronic and chill-out music, designed to create a relaxed yet energizing atmosphere for any occasion.",
        tags: ["electronic-music", "chill-out", "downtempo", "ambient-electronic", "relaxation", "modern-soundscapes"],
        idealSongLength: "3-5 minutes",
        releaseDate: "2024-06-24",
        cover: chillElectronicVibesCover,
        streamingLinks: {
            spotify: "https://open.spotify.com/album/2NGePNSc7Nlo8U9WMhzK2r",
            appleMusic: "https://music.apple.com/us/artist/elegant-echoes/1335856201",
            amazonMusic: "https://music.amazon.it/albums/B0D6X6FNFS",
            youtube: "https://www.youtube.com/playlist?list=OLAK5uy_llPyNAzDx6bmdkMoPPTb1B7n7oZy_t9j8",
        },
        seoKeywords: "electronic music, chill-out playlist, downtempo beats, ambient electronic, relaxation sounds, modern soundscapes"
    },
    {
        id: "NS006",
        title: "Nature's Serenity: Ambient Soundscapes",
        genre: "Nature Sounds",
        description: "Bring the tranquility of nature indoors with our curated collection of serene nature sounds, perfect for relaxation and mindfulness practices.",
        tags: ["nature-sounds", "relaxation-audio", "mindfulness", "meditation-sounds", "environmental-audio", "tranquility"],
        idealSongLength: "5-7 minutes",
        releaseDate: "2024-06-24",
        cover: naturesSerenityCover,
        streamingLinks: {
            spotify: "https://open.spotify.com/artist/429712PQ3i0XrZq5Z5KVXl",
            appleMusic: "https://music.apple.com/us/artist/elegant-echoes/1335856201",
            amazonMusic: "https://music.amazon.it/albums/B0D6YWSGCG",
            youtube: "https://www.youtube.com/playlist?list=OLAK5uy_lekqoN8e8iMk-AvPctARB4n29J0O712JY",
        },
        seoKeywords: "nature sounds, relaxation audio, mindfulness practice, meditation sounds, environmental audio, tranquility playlist"
    },
    {
        id: "JCA007",
        title: "Jazz Café Ambience: Smooth and Mellow",
        genre: "Smooth Jazz",
        description: "Transform any space into a sophisticated jazz café with our collection of smooth jazz tracks, perfect for creating a relaxed yet refined atmosphere.",
        tags: ["smooth-jazz", "cafe-music", "background-jazz", "instrumental", "relaxation", "sophisticated-sounds"],
        idealSongLength: "3-5 minutes",
        releaseDate: "2024-06-24",
        cover: jazzCafeAmbienceCover,
        streamingLinks: {
            spotify: "https://open.spotify.com/album/4MoG9GuTWOhWjkIuxiiaAP",
            appleMusic: "https://music.apple.com/us/artist/elegant-echoes/1335856201",
            amazonMusic: "https://music.amazon.it/albums/B0D6Z8LD4Q",
            youtube: "https://www.youtube.com/playlist?list=OLAK5uy_ls2iFfJAQebOQ3dVGV3ql_jQrBUKHS1GY",
        },
        seoKeywords: "smooth jazz, cafe music, background jazz, instrumental tracks, relaxation playlist, sophisticated sounds"
    },
    {
        id: "UA008",
        title: "Uplifting Acoustic: Guitar Melodies",
        genre: "Acoustic",
        description: "Elevate your mood with our collection of uplifting acoustic tracks, featuring soulful guitar melodies and positive vibes.",
        tags: ["acoustic-music", "guitar-melodies", "uplifting-sounds", "positive-vibes", "instrumental", "mood-enhancement"],
        idealSongLength: "3-4 minutes",
        releaseDate: "2024-06-24",
        cover: upliftingAcousticCover,
        streamingLinks: {
            spotify: "https://open.spotify.com/album/3VLT0K1gaArH7SMPSVZqua",
            appleMusic: "https://music.apple.com/us/artist/elegant-echoes/1335856201",
            amazonMusic: "https://music.amazon.it/albums/B0D6YYCZNP",
            youtube: "https://www.youtube.com/playlist?list=OLAK5uy_manSPsfZ8m6c06EgiXU6cMBcHgSr_ZwHQ",
        },
        seoKeywords: "acoustic music, guitar melodies, uplifting sounds, positive vibes playlist, instrumental acoustic, mood enhancement"
    },
    {
        id: "MS009",
        title: "Meditative Soundscapes",
        genre: "Meditation",
        description: "Dive deep into relaxation with our meditative soundscapes, designed to enhance your mindfulness practice and promote inner peace.",
        tags: ["meditation-music", "mindfulness-audio", "relaxation-sounds", "zen-playlist", "spiritual-music", "healing-sounds"],
        idealSongLength: "5-7 minutes",
        releaseDate: "2024-06-24",
        cover: meditativeSoundscapesCover,
        streamingLinks: {
            spotify: "https://open.spotify.com/artist/429712PQ3i0XrZq5Z5KVXl",
            appleMusic: "https://music.apple.com/us/artist/elegant-echoes/1335856201",
            amazonMusic: "https://music.amazon.it/albums/B0D6YWXLBZ",
            youtube: "https://www.youtube.com/playlist?list=OLAK5uy_l_b5Kz8i7bQnTE37kTlzCyGn8aYpKT40M",
        },
        seoKeywords: "meditation music, mindfulness audio, relaxation sounds, zen playlist, spiritual music, healing sounds"
    },
    {
        id: "RLG010",
        title: "Retro Lounge Grooves: Vintage Vibes",
        genre: "Retro Lounge",
        description: "Travel back in time with our collection of retro lounge grooves, blending vintage vibes with modern production for a unique listening experience.",
        tags: ["retro-music", "lounge-grooves", "vintage-vibes", "modern-retro", "chill-out", "nostalgic-sounds"],
        idealSongLength: "3-5 minutes",
        releaseDate: "2024-06-24",
        cover: retroLoungeGroovesCover,
        streamingLinks: {
            spotify: "https://open.spotify.com/album/7idh7X0DS2AqGWjCkmyBiw",
            appleMusic: "https://music.apple.com/us/album/retro-lounge-grooves-vintage-vibes/1751925333",
            amazonMusic: "https://music.amazon.it/albums/B0D6Z5JSGW",
            youtube: "https://www.youtube.com/playlist?list=OLAK5uy_mOLDJZ0kv8tWWP49f3g9f2pUf4V6i12i0",
        },
        seoKeywords: "retro music, lounge grooves, vintage vibes, modern retro, chill-out playlist, nostalgic sounds"
    }
];
