// src/styles/GlobalStyles.js
import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  :root {
    --primary-color: #0a0a0a;
    --secondary-color: #f0f0f0;
    --accent-color: #ff3366;
    --text-color: #ffffff;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html, body {
    height: 100%;
    overflow-x: hidden;
  }

  body {
    font-family: 'Space Mono', monospace;
    background-color: var(--primary-color);
    color: var(--text-color);
    line-height: 1.6;
  }

  #root {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 1rem;
    min-height: 100vh;
  }

  .noise {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 250 250' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
    opacity: 0.05;
    pointer-events: none;
    z-index: -1;
  }

  @keyframes glitch {
    0% {
      text-shadow: 0.05em 0 0 var(--accent-color), -0.05em -0.025em 0 cyan, -0.025em 0.025em 0 magenta;
    }
    14% {
      text-shadow: 0.05em 0 0 var(--accent-color), -0.05em -0.025em 0 cyan, -0.025em 0.025em 0 magenta;
    }
    15% {
      text-shadow: -0.05em -0.025em 0 var(--accent-color), 0.025em 0.025em 0 cyan, -0.05em -0.05em 0 magenta;
    }
    49% {
      text-shadow: -0.05em -0.025em 0 var(--accent-color), 0.025em 0.025em 0 cyan, -0.05em -0.05em 0 magenta;
    }
    50% {
      text-shadow: 0.025em 0.05em 0 var(--accent-color), 0.05em 0 0 cyan, 0 -0.05em 0 magenta;
    }
    99% {
      text-shadow: 0.025em 0.05em 0 var(--accent-color), 0.05em 0 0 cyan, 0 -0.05em 0 magenta;
    }
    100% {
      text-shadow: -0.025em 0 0 var(--accent-color), -0.025em -0.025em 0 cyan, -0.025em -0.05em 0 magenta;
    }
  }
`;