import React from 'react';
import styled from 'styled-components';

const AboutSection = styled.section`
  grid-column: 1 / -1;
  padding: 6rem 2rem;
  text-align: center;
`;

const ContentWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const About = () => (
  <AboutSection id="about">
    <ContentWrapper>
      <h2>About Elegant Echoes</h2>
      <p>Elegant Echoes is an avant-garde musical project that pushes the boundaries of auditory perception. Our compositions blend ambient textures, experimental sounds, and intricate rhythms to create immersive sonic landscapes.</p>
      <p>Founded by a collective of sound artists and electronic musicians, we strive to challenge conventional notions of music and explore the liminal spaces between noise and melody, structure and chaos.</p>
    </ContentWrapper>
  </AboutSection>
);

export default About;