// src/App.js
import React, {useEffect, useState} from 'react';
import {HelmetProvider} from 'react-helmet-async';
import {About, Albums, Contact, Footer, Header, Home} from './components';
import {GlobalStyles} from './styles/GlobalStyles';
import {AppProvider} from './context/AppContext';
import SEO from './components/SEO';
import PrivacyPolicy from './components/PrivacyPolicy';
import CookieConsent, {Cookies} from "react-cookie-consent";
import logo from './images/logo.jpg';
import ReactGA from "react-ga4";

const MEASUREMENT_ID = 'G-25PM6Z2CQ5'; // Replace with your actual Measurement ID
const STREAM_ID = '8413370167';

export const initGA = () => {
  ReactGA.initialize(MEASUREMENT_ID, {
    gaOptions: {
      streamId: STREAM_ID
    },
    gtagOptions: {
      send_page_view: false // This prevents duplicate page views
    }
  });
};

export const logPageView = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname + window.location.search,
    stream_id: STREAM_ID
  });
};

export const logEvent = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
    stream_id: STREAM_ID
  });
};

const App = () => {
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [hasConsent, setHasConsent] = useState(false);
  useEffect(() => {
    const consent = Cookies.get('CookieConsent');
    if (consent === 'true') {
      initGA();
      logPageView();
      setHasConsent(true);
    }
  }, [hasConsent]);

  const handleAccept = () => {
    initGA();
    logPageView();
    setHasConsent(true);
  };

  const schema = {
    "@context": "https://schema.org",
    "@type": "MusicGroup",
    "name": "Elegant Echoes",
    "url": "https://www.elegantechoesmusic.com",
    "logo": logo,
    "sameAs": [
      "https://www.instagram.com/elegantechoes",
      "https://open.spotify.com/artist/429712PQ3i0XrZq5Z5KVXl",
    ],
    "genre": ["Ambient", "Jazz", "Lo-Fi", "Classical"],
    "description": "Elegant Echoes creates immersive soundscapes that enhance every moment of your day, from productivity-boosting ambient tracks to relaxing jazz melodies."
  };

  return (
    <HelmetProvider>
      <AppProvider>
        <GlobalStyles />
        <SEO
          title="Elegant Echoes | Immersive Soundscapes for Every Moment"
          description="Discover Elegant Echoes' collection of ambient, jazz, and lo-fi music designed to enhance your daily life, from focus-boosting tracks to relaxing melodies."
          keywords="ambient music, jazz, lo-fi, productivity music, relaxation sounds, instrumental tracks"
          schema={schema}
        />
        <div className="noise" />
        <Header />
        <Home />
        <About />
        <Albums />
        <Contact />
        <Footer />
        {showPrivacyPolicy && (
            <PrivacyPolicy onClose={() => setShowPrivacyPolicy(false)}/>
        )}
        <CookieConsent
            location="bottom"
            buttonText="Accept"
            declineButtonText="Decline"
            cookieName="CookieConsent"
            style={{background: "#2B373B"}}
            buttonStyle={{color: "#4e503b", fontSize: "13px"}}
            declineButtonStyle={{color: "#4e503b", fontSize: "13px"}}
            expires={150}
            enableDeclineButton
            onAccept={handleAccept}
        >
          This website uses cookies to enhance the user experience and analyze site traffic.
          By clicking "Accept", you consent to our use of cookies.
          <button
              onClick={() => setShowPrivacyPolicy(true)}
              style={{
                color: "#ffd42d",
                marginLeft: "5px",
                background: "none",
                border: "none",
                textDecoration: "underline",
                cursor: "pointer"
              }}
          >
            Learn more
          </button>
        </CookieConsent>
      </AppProvider>
    </HelmetProvider>
  );
};

export default App;