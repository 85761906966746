import React from 'react';
import styled from 'styled-components';
import {albumsData} from '../data/albumsData';
import SEO from './SEO';
import {FaAmazon, FaApple, FaSpotify, FaYoutube} from 'react-icons/fa';

const AlbumsSection = styled.section`
  grid-column: 1 / -1;
  padding: 6rem 2rem;
  text-align: center;
`;

const AlbumGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 2rem auto 0;
`;

const AlbumCard = styled.article`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;

  &:hover {
      transform: scale(1.02);
  }
`;

const AlbumCover = styled.img`
  width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
`;

const AlbumInfo = styled.div`
  padding: 1rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;

const AlbumTitle = styled.h3`
    font-family: 'Syncopate', sans-serif;
    margin-bottom: 0.5rem;
    font-size: 1rem;
`;

const AlbumDescription = styled.p`
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
    flex-grow: 1;
`;

const TagsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0.3rem;
    margin-bottom: 1rem;
`;

const Tag = styled.span`
    background-color: rgba(255, 255, 255, 0.2);
    padding: 0.2rem 0.4rem;
    border-radius: 4px;
    font-size: 0.7rem;
`;

const StreamingLinks = styled.div`
    display: flex;
    justify-content: space-around;
    margin-bottom: 1rem;
`;

const StreamingIcon = styled.a`
    color: white;
    font-size: 1.5rem;
    transition: color 0.3s ease;

    &:hover {
        color: var(--accent-color);
  }
`;


const Album = ({album}) => {

    return (
        <AlbumCard>
            <AlbumCover src={album.cover} alt={`${album.title} album cover`} loading="lazy"/>
            <AlbumInfo>
                <AlbumTitle>{album.title}</AlbumTitle>
                <AlbumDescription>{album.description}</AlbumDescription>
                <TagsContainer>
                    {album.tags.slice(0, 3).map((tag, index) => (
                        <Tag key={index}>{tag}</Tag>
                    ))}
                </TagsContainer>
                <StreamingLinks>
                    {album.streamingLinks.spotify && (
                        <StreamingIcon href={album.streamingLinks.spotify} target="_blank" rel="noopener noreferrer">
                            <FaSpotify/>
                        </StreamingIcon>
                    )}
                    {album.streamingLinks.appleMusic && (
                        <StreamingIcon href={album.streamingLinks.appleMusic} target="_blank" rel="noopener noreferrer">
                            <FaApple/>
                        </StreamingIcon>
                    )}
                    {album.streamingLinks.amazonMusic && (
                        <StreamingIcon href={album.streamingLinks.amazonMusic} target="_blank"
                                       rel="noopener noreferrer">
                            <FaAmazon/>
                        </StreamingIcon>
                    )}
                    {album.streamingLinks.youtube && (
                        <StreamingIcon href={album.streamingLinks.youtube} target="_blank" rel="noopener noreferrer">
                            <FaYoutube/>
                        </StreamingIcon>
                    )}
                </StreamingLinks>
            </AlbumInfo>
        </AlbumCard>
    );
};

const Albums = () => {
  const albumsSchema = {
    "@context": "https://schema.org",
    "@type": "MusicPlaylist",
    "name": "Elegant Echoes Album Collection",
    "numTracks": albumsData.length,
    "track": albumsData.map(album => ({
      "@type": "MusicRecording",
      "name": album.title,
      "genre": album.genre,
      "datePublished": album.releaseDate
    }))
  };

  return (
    <AlbumsSection id="albums">
      <SEO
        title="Elegant Echoes Albums | Immersive Soundscapes for Every Mood"
        description="Explore our diverse collection of albums, from vintage jazz to ambient electronic, designed to enhance your listening experience and set the perfect mood."
        keywords="music albums, ambient sounds, jazz, lo-fi, meditation music, relaxation audio, productivity enhancement"
        schema={albumsSchema}
      />
      <h1>Our Albums</h1>
      <p>Discover our collection of immersive soundscapes, crafted to enhance every moment of your day.</p>
      <AlbumGrid>
        {albumsData.map((album) => (
            <Album key={album.id} album={album}/>
        ))}
      </AlbumGrid>
    </AlbumsSection>
  );
};

export default Albums;